.hideSubhead {
    color: var(--vkui--color_text_accent);
}

.addresessCount {
    color: #818c99;
    font-size: 13px;
}

.addresessHeader {
    padding-bottom: 15px;
    display: flex;
    gap: 6px;
    align-items: center;
}
