.likeIcon {
    position: absolute;
    right: 16px;
    top: 16px;
}

.blockHidden {
    opacity: 0 !important;
    transition: opacity 500ms ease-in-out;
}

.blockShown {
    opacity: 1 !important;
    transition: opacity 500ms ease-in-out;
}

