.columnWrapper {
    display: flex;
    flex-direction: column;
}

.addressContainer {
    display: flex;
    gap: 12px;
    padding-top: 12px;
    font-size: 15px;
    font-weight: 500;
}

.infoContainer {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;
}

.buttonContainer {
    font-size: 15px;
    font-weight: 400;
    color: #2688eb;
    display: flex;
    gap: 4px;
    align-items: center;
    border: none;
    padding: 0;
    background: none;
    cursor: pointer;
}

.textSchedule {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
}

.separator {
    background: #0000001f;
    height: 1px;
}
