.colorPickerContainer {
    margin: 0 20%;
}

@media screen and (max-width: 600px) {
    .colorPickerContainer {
        margin: 0 8px;
    }
}

.chrome-picker > div:first-child {
    border-top-right-radius: 6px !important;
    border-top-left-radius: 6px !important;
    padding-bottom: 25% !important;
    box-sizing: border-box;
}
