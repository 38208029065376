#filterIcon {
    padding-left: 10px;
}

.salonsContainer {
    padding-top: 68px;
}

.emptySalons {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.emptyFiltersButton {
    margin-top: 8px;
}
