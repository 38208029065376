#banner {
    color: #000;
    font-size: 14px;
}

.modalHeaderText {
    margin: 0 10px;
}

#modalHeader {
    padding: 0 16px;
}

.groupHeader {
    margin: 12px 16px;
    display: grid;
    grid-template-columns: 1fr auto;
}

.salonName {
    display: inline;
    margin-right: 6px;
}

.salonServiceCounter {
    display: inline;
}

.salonServiceCounter, .salonAddress {
    color: var(--vkui--color_text_secondary);
}

.orderHereLink {
    font-size: 15px;
}

.disabledSection {
    margin: 0 4px;
}


