.modalPage {
  padding-bottom: 20px;
}

.header {
  position: absolute;
  top: -4px;
  right: 0;
}

.title {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.38px;
  text-align: center;
}

.subtitle {
  color: #818c99;
  font-size: 14px;
  line-height: 18px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  text-wrap: wrap;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.caption {
  margin-top: 10px;
  margin-bottom: 5px;
  text-align: center;
  font-size: 12px;
  line-height: 14px;

  color: #6D7885;
}