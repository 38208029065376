.conicGradinent {
    background: conic-gradient(
        #d60104,
        #fd9800,
        #d8dc00,
        #3ade00,
        #00fced,
        #0034eb,
        #6100af,
        #d6009a,
        #d60000
    ) !important;
}

.pearl {
    background: linear-gradient(135deg, #f716e0 0%, #2ad7cc 82.5%) !important;
}
