.divider {
    width: 100%;
    height: 1px;
    background: #d7d8d9;
    margin-top: 12px;
}

.cardDescriptionDate {
    font-weight: 400;
    height: 20px;
    color: #6d7885;
    font-size: 15px;
    flex-grow: 1;
}

.cardDescriptionActions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
    flex-grow: 1;
}

.cardDescriptionContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    height: 32px;
    margin-top: 12px;
}
