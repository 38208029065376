.scrollableContainer {
    position: relative;
    padding: 10px;
    overflow-x: hidden;
    overflow-y: hidden;
    -ms-overflow-style: none; /* IE и Edge */
    scrollbar-width: none; /* Firefox */
}

.scrollableContainer ::-webkit-scrollbar {
    display: none;
}

.list {
    display: flex;
    overflow-x: auto;
    list-style: none;
    padding: 0;
    margin: 0;
}

.list > * {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.shadowWrapper {
    width: 30px;
    height: 100%;
    overflow: hidden;
    z-index: 1;
    position: absolute;
    top: 0;
}

.leftShadowWrapper {
    left: 0;
}

.rightShadowWrapper {
    right: 0;
}

.shadow {
    position: absolute;
    box-shadow: 0 0 30px -8px #232628;
    position: absolute;
    z-index: 1;
    top: 50%;
    right: -25px;
    width: 24px;
    height: 80%;
    border-radius: 50%;
    transform: translateY(-50%);
}

.leftShadow {
    box-shadow: 0 0 30px -8px #232628;
    left: -25px;
}

.rightShadow {
    box-shadow: 0 0 30px -8px #232628;
    right: -25px;
}

.shadow--hidden {
    display: none;
}

.button {
    background-color: rgba(256, 256, 256, 0.9);
    position: absolute;
    color: #818c99;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 2;
    border-radius: 100%;
}

.buttonLeft {
    left: 0;
}

.buttonRight {
    right: 0;
}

.button--hidden {
    display: none;
}
