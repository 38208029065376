.card {
    margin-bottom: 8px;
}

.cardCell {
    height: 81px;
    border-radius: 9px;
    white-space: pre-wrap;
}
.positionTop {
    position: relative;
    z-index: 10;
}
