.aboutContestButton {
  background: white;

  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 2px;
  display: flex;
  align-items: center;
  gap: 5px;

  position: fixed;
  bottom: 100px;
  right: 12px;

  border: none;
  border-radius: 10px;
  font-size: 12px;
  cursor: pointer;
}

