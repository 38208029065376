.keyboardGridContainer {
    display: grid;
    grid-row-gap: 16px;
    grid-template-columns: repeat(5, 1fr);
    align-items: center;
    justify-content: center;
    justify-items: center;
    grid-column-gap: 8px;
    padding: 16px;
}

@media screen and (min-width: 762px) {
    .keyboardGridContainer {
        grid-template-columns: repeat(6, 1fr);
        grid-column-gap: 16px;
    }
}
