.panelHeaders {
    margin-bottom: 28px;
    text-align: center;
}

.modalContainer .vkuiModalRoot__mask {
    opacity: 0;
    background-color: rgba(0, 0, 0, 0);
}

.cellWithoutPadding .vkuiSimpleCell {
    padding: 0;
    cursor: pointer;
}

.vkuiSimpleCell .vkuiSimpleCell__content {
    display: block;
}

#root {
    height: 100%;
}

.vkuiSnackbar__body {
    align-items: start;
}
