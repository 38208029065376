.tabbar {
   -ms-overflow-style: none; /*IE и Edge*/
    scrollbar-width: none; /*Firefox*/
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
}

.tabbar > button {
    min-width: 60px !important;
}

.tabbar::-webkit-scrollbar {
    display: none;
}
