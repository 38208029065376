.flexContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 9px;
}

.image {
    justify-self: center;
}

.title {
    margin-top: 9px;
    text-align: center;
}

.subHead {
    text-align: center;
    color: var(--vkui--color_text_secondary);
    margin-bottom: 5px;
}

.linkAboutPlace {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    grid-gap: 4px;
    cursor: pointer;
    user-select: none;
    color: var(--vkui--color_text_accent);
}

.subHead {
    font-size: var(--vkui--font_subhead--font_size--compact);
    text-align: start;
}
