.serviceDescriptionContainer {
    display: flex;
    width: 100%;
}

.subHead {
    text-align: start;
    color: var(--vkui--color_text_secondary);
    font-size: var(--vkui--font_subhead--font_size--compact);
}

.serviceDuration {
    margin-left: auto;
}

.servicePrice {
    margin-left: auto;
    white-space: nowrap;
}

.serviceTextActive {
    color: #2d81e0;
}

.serviceTextDisabled {
    color: #818c99;
}
