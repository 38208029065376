.buttonsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    margin: 12px 12px 0 12px;
}

.subtitle {
    color: #818c99;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
}

.subtitleCell {
    text-overflow: ellipsis;
    overflow: hidden;
}
