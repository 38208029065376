.cardHeader {
    text-align: center;
    color: #6d7885;
    max-width: 64px;
    height: 28px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

}

.card {
    background-size: cover;
    background-position: center;
    margin-left: 8px;
    height: 64px;
    width: 64px;
    margin: 0 auto;
}
