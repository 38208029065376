.modalContainer .vkuiModalRoot__mask {
    opacity: 0 !important;
    background-color: rgba(0, 0, 0, 0);
}

.scrollableContainer .button {
    margin: 0 12px;
}

.scrollableContainer .list {
    margin: 0 12px;
}
