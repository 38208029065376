.text {
    font-size: var(--vkui--font_headline2--font_size--compact);
    color: var(--vkui--color_text_secondary);
}

.flexContainer {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
}
