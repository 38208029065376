.infoContainer {
    display: flex;
    border-radius: 10px;
    gap: 12px;
    padding: 14px;
    background: #f5f5f5;
    margin: 12px;
}

.textContainer {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    gap: 12px;
}
