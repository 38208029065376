.tabsItem {
    border-radius: 5px;
    padding: 5px 10px;
    color: #818C99;
    box-sizing: border-box;
    margin-right: 16px;
}

.tabsItemSelected {
    background-color: #EBEDF0;
}

.tabsItem:not(:last-child) {
    margin-right: 16px;
}
