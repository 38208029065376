.adButton {
    background: white;
    padding: 12px 15px;
    color: #000000;
    display: flex;
    align-items: center;
    gap: 8px;
    position: fixed;
    bottom: 100px;
    right: 12px;
    border: none;
    border-radius: 10px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    cursor: pointer;
}

.countContainer {
    background: #ff3347;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 18px;
    height: 18px;
}
